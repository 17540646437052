import instance from "../../utils/axios";
import { AxiosPromise } from "axios";

export class PaginatedResponse<T> {
    page: number;
    page_size: number;
    page_count: number;
    total: number;
    data: T[];
}

export class Api {
    queryString(obj: Record<string, unknown>): string {
        const s = Object.keys(obj)
            .map((key) => {
                if (obj[key] === null || obj[key] === "") {
                    return null;
                }

                return key + "=" + obj[key];
            })
            .filter((s) => s)
            .join("&");

        return s ? "?" + s : "";
    }

    get<T>(path: string): AxiosPromise<T> {
        return instance.get(path);
    }

    post<T>(path: string, data: any): AxiosPromise<T> {
        return instance.post(path, data);
    }

    patch<T>(path: string, data: any): AxiosPromise<T> {
        return instance.patch(path, data);
    }

    put<T>(path: string, data: any): AxiosPromise<T> {
        return instance.put(path, data);
    }

    delete<T>(path: string): AxiosPromise<T> {
        return instance.delete(path);
    }
}
