import en from "./en.json";
import nl from "./nl.json";
import { createI18n } from "vue-i18n";
import { getCurrentInstance } from "vue";

export const i18n = createI18n({
    locale: window.viaduct?.locale || "en",
    fallbackLocale: "en",
    messages: {
        en: en,
        nl: nl,
    },
    missingWarn: false,
    fallbackWarn: false,
    allowComposition: true,
});
