import { userApi } from "../scripts/api/user";
import { RolesResponse } from "../types/role";
import { getCookie } from "../utils/axios";

class AuthStore {
    roles: Partial<RolesResponse> = {};
    member = false;
    favourer = false;

    async reloadRoles() {
        if (!this.isAnonymous()) {
            this.roles = (await userApi.getRoles(null)).data;
            const userData = (await userApi.getUserData(null)).data;
            this.member = userData.member;
            this.favourer = userData.favourer;
        }
    }

    isAnonymous() {
        return !getCookie("access_token");
    }
}

export const authStore = new AuthStore();
