import { Api, PaginatedResponse } from "./api";
import { AxiosResponse } from "axios";
import { MailingList } from "../../types/mailinglists";
import { User } from "../../types/user";
import { RolesResponse } from "../../types/role";
import { TaskResponse } from "./task";
import Flask from "../../utils/flask";

export type Groups = {
    id: number;
    name: string;
    maillist: string;
    mailtype: string;
};

export type MemberOfMerit = Pick<
    User,
    "id" | "first_name" | "last_name" | "member_of_merit_date"
> & {
    name: string;
    path: string;
};

export interface GroupDetails {
    member_since: string;
    member_until: string | null;
    group_name: string;
    group_id: number;
}

class UserApi extends Api {
    public async getUserData(
        userId: number | null
    ): Promise<AxiosResponse<User>> {
        return this.get(
            Flask.url_for("api.user", { user: userId ? userId : "self" })
        );
    }

    public async setUserData(userId: number | null, data: Partial<User>) {
        return this.patch(
            Flask.url_for("api.user", { user: userId ? userId : "self" }),
            data
        );
    }

    public async getUserGroups(
        userId: number | null = null
    ): Promise<AxiosResponse<Groups[]>> {
        return this.get(
            Flask.url_for("api.user.groups", { user: userId ? userId : "self" })
        );
    }

    public async getUserGroupDetails(
        userId: number | null = null
    ): Promise<AxiosResponse<{ groups: GroupDetails[] }>> {
        return this.get(
            Flask.url_for("api.user.groups_details", {
                user: userId ? userId : "self",
            })
        );
    }

    public async getUserMailingListSubscriptions(
        userId: number | null
    ): Promise<AxiosResponse<MailingList[]>> {
        return this.get(
            Flask.url_for("api.user.mailinglist_subscriptions", {
                user: userId ? userId : "self",
            })
        );
    }

    public async setUserMailingListSubscribed(
        userId: number | null,
        mailingList: MailingList,
        subscribed: boolean
    ): Promise<AxiosResponse<TaskResponse>> {
        return this.post(
            Flask.url_for("api.user.mailinglist_subscriptions", {
                user: userId ? userId : "self",
            }),
            {
                id: mailingList.id,
                subscribed: subscribed,
            }
        );
    }

    async getUsers(search: string, page: number) {
        return this.get<PaginatedResponse<User>>(
            Flask.url_for("api.users", {
                search: search,
                page: page,
            })
        );
    }

    async getRoles(userId: null | number) {
        return this.get<RolesResponse>(
            Flask.url_for("api.user.roles", { user: userId ? userId : "self" })
        );
    }

    async getMembersofMerit() {
        return this.get<MemberOfMerit[]>(Flask.url_for("api.user.merit"));
    }
}

export const userApi = new UserApi();
