<template lang="pug">
a-config-provider(:locale="computedLocale")
    router-view
</template>

<script setup lang="ts">
import { ConfigProvider as AConfigProvider } from "ant-design-vue";
import nl from "ant-design-vue/lib/locale/nl_NL";
import en from "ant-design-vue/lib/locale/default";
import { computed, provide } from "vue";

provide("locale", window.viaduct.locale);

const computedLocale = computed(() => {
    return window.viaduct.locale == "nl" ? nl : en;
});
</script>
