import { Api, PaginatedResponse } from "./api";
import { AxiosResponse } from "axios";
import {
    Company,
    CompanyBanner,
    CompanyBannerBase,
    CompanyInfo,
    CompanyJob,
    CompanyProfile,
    CompanyProfilesList,
    ContractOfServiceTypes,
} from "../../types/company";
import Flask from "../../utils/flask";

class CompanyApi extends Api {
    public async getCompany(
        companyId: number | string
    ): Promise<AxiosResponse<Company>> {
        return this.get(Flask.url_for("api.company", { company: companyId }));
    }

    public async getCompanyInfo(
        companyId: number | string
    ): Promise<AxiosResponse<CompanyInfo>> {
        return this.get(
            Flask.url_for("api.company.public", { company: companyId })
        );
    }

    public async getCompanyBanner(
        companyId: number | string
    ): Promise<AxiosResponse<CompanyBanner>> {
        return this.get(
            Flask.url_for("api.company_banner", { company: companyId })
        );
    }

    public async getCompanyProfiles(): Promise<
        AxiosResponse<CompanyProfilesList[]>
    > {
        return this.get(Flask.url_for("api.companies.profilelist"));
    }

    public async getCompanyProfile(
        companyId: number | string
    ): Promise<AxiosResponse<CompanyProfile>> {
        return this.get(
            Flask.url_for("api.company_profile.id", { company: companyId })
        );
    }

    public async getCompanyProfileBySlug(
        companySlug: string
    ): Promise<AxiosResponse<CompanyProfile>> {
        return this.get(
            Flask.url_for("api.company_profile.slug", {
                company: companySlug,
            })
        );
    }

    public async getCompanyJob(
        jobId: number
    ): Promise<AxiosResponse<CompanyJob>> {
        return this.get(Flask.url_for("api.job", { job_id: jobId }));
    }

    public async getContractOfServiceTypes(): Promise<
        AxiosResponse<ContractOfServiceTypes>
    > {
        return this.get(Flask.url_for("api.jobs.types"));
    }

    public async getCompanyJobs(
        search: string,
        contract_of_service: string[],
        page: number
    ): Promise<AxiosResponse<PaginatedResponse<CompanyJob>>> {
        return this.get(
            Flask.url_for("api.jobs", {
                search: search,
                contract_of_service: contract_of_service,
                page: page,
            })
        );
    }

    public async getJobsOfCompany(
        companySlug: string
    ): Promise<AxiosResponse<CompanyJob[]>> {
        return this.get(
            Flask.url_for("api.company.jobs.slug", { company: companySlug })
        );
    }

    public async updateCompanyProfile(
        companyId: number | string,
        formValues
    ): Promise<AxiosResponse<CompanyProfile>> {
        return this.put(
            Flask.url_for("api.company_profile.id", {
                company: companyId,
            }),
            formValues
        );
    }

    public async setCompanyProfile(
        companyId: number | string,
        formValues
    ): Promise<AxiosResponse<CompanyProfile>> {
        return this.post(
            Flask.url_for("api.company_profile.id", {
                company: companyId,
            }),
            formValues
        );
    }

    public async updateCompanyBanner(
        companyId: number | string,
        formValues
    ): Promise<AxiosResponse<CompanyBanner>> {
        return this.put(
            Flask.url_for("api.company_banner", {
                company: companyId,
            }),
            formValues
        );
    }

    public async setCompanyBanner(
        companyId: number | string,
        formValues
    ): Promise<AxiosResponse<CompanyBanner>> {
        return this.post(
            Flask.url_for("api.company_banner", {
                company: companyId,
            }),
            formValues
        );
    }

    async getBanners(): Promise<AxiosResponse<CompanyBannerBase[]>> {
        return this.get(Flask.url_for("api.banners", {}));
    }
}

export const companyApi = new CompanyApi();
