import Axios from "axios";

export function getCookie(cname) {
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

const headers = {
    "Content-Type": "application/json",
};
if (getCookie("access_token")) {
    headers["Authorization"] = "Bearer " + getCookie("access_token");
}

const instance = Axios.create({
    baseURL: window.location.origin,
    timeout: 100000,
    headers: headers,
});

export default instance;
