<template lang="pug">
a-date-picker.w-100(:value="momentValue", @change="onChange", :format="format")
    .form-group
        label(:for="id") {{ label }}
        input.form-control(
            :class="{ 'is-invalid': !!errors.length }",
            :id="id",
            :name="name",
            :required="true",
            type="text",
            :value="stringValue",
            autocomplete="off"
        )
        p.invalid-feedback(v-for="error in errors") {{ error }}
</template>

<script lang="ts">
import { DatePicker } from "ant-design-vue";
import moment from "moment";
import { defineComponent } from "vue";

export default defineComponent({
    name: "wtform-date-picker",
    components: { "a-date-picker": DatePicker },
    data() {
        const stringValue = "";
        return {
            momentValue: undefined as moment.Moment | undefined,
            stringValue,
        };
    },
    created() {
        this.momentValue = this.value ? moment(this.value) : undefined;
        this.stringValue = this.momentValue
            ? this.momentValue.format(this.format)
            : "";
    },
    methods: {
        onChange(dateMoment, dateString) {
            this.momentValue = dateMoment;
            this.stringValue = dateString;
        },
    },
    props: {
        errors: { type: Array, default: () => [] },
        value: {
            type: String,
        },
        format: {
            type: String,
        },
        required: {
            type: Boolean,
        },
        label: {
            type: String,
        },
        name: {
            type: String,
        },
        id: {
            type: String,
        },
    },
});
</script>
