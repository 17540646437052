<template lang="pug">
carousel.w-100(
    :autoplay="true",
    :loop="true",
    :perPageCustom="[ [0, 1], [480, 2], [768, 4], [1024, 6], ]",
    :paginationEnabled="false",
    :navigationEnabled="false",
    :autoplayTimeout="4000",
    :scrollPerPage="false",
    :centerMode="true",
    ref="carousel"
)
    slide.slides(v-for="banner in banners", :key="banner.id")
        span.vertical-helper
        a(:href="banner.website")
            img.company-logo(:src="companyLogoSrc(banner)")
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Carousel, Slide } from "@jambonn/vue-concise-carousel";
import { companyApi } from "../scripts/api/company";
import { CompanyBanner, CompanyBannerBase } from "../types/company";
import Flask from "../utils/flask";
import shuffle from "../utils/shuffle";
import "@jambonn/vue-concise-carousel/lib/vue-concise-carousel.css";

export default defineComponent({
    components: {
        Carousel,
        Slide,
    },
    data() {
        return {
            banners: [] as CompanyBanner[],
        };
    },
    async mounted() {
        this.banners = await this.getBanners();
        //Fix for https://github.com/SSENSE/vue-carousel/issues/444
        setTimeout((this.$refs.carousel as Carousel).computeCarouselWidth, 300);
    },
    methods: {
        async getBanners(): Promise<CompanyBannerBase[]> {
            const resp = (await companyApi.getBanners()).data;
            return shuffle(resp);
        },
        companyLogoSrc(banner: CompanyBannerBase) {
            return Flask.url_for("company.view_logo", {
                company: String(banner.company_id),
                v: banner.logo_id,
            });
        },
    },
});
</script>

<style scoped lang="sass">
$logo-height: 5rem

.VueCarousel-slide
    height: $logo-height
    width: 100%
    text-align: center

    .vertical-helper
        display: inline-block
        height: $logo-height
        vertical-align: middle

    a
        height: $logo-height

        .company-logo
            max-width: 100%
            max-height: $logo-height
            height: auto
            width: auto
            padding: 0 1rem
</style>
