import {
    createRouter,
    createWebHistory,
    RouteLocationNormalized,
} from "vue-router";
import { authStore } from "../store/auth";

const RedirectOverview = () =>
    import("../components/redirect/redirect_overview.vue");

const PimpyCreateMinute = () => import("../components/pimpy/create_minute.vue");
const PimpyCreateTask = () => import("../components/pimpy/create_task.vue");
const PimpyBase = () => import("../components/pimpy/pimpy_base.vue");
const PimpyTasks = () => import("../components/pimpy/pimpy_tasks.vue");
const PimpyMinutes = () => import("../components/pimpy/pimpy_minutes.vue");
const PimpySingleMinute = () =>
    import("../components/pimpy/pimpy_single_minute.vue");
const UserOverview = () => import("../components/user/user_overview.vue");
const UserProfileEdit = () => import("../components/user/UserProfileEdit.vue");
const UserManualRegister = () =>
    import("../components/user/UserManualRegister.vue");
const UserRegister = () => import("../components/user/UserRegister.vue");
const MembersOfMerit = () => import("../components/user/MembersOfMerit.vue");
const SearchIndex = () => import("../components/search/search_index.vue");
const TwoFactorAuthentication = () =>
    import("../components/user/two_factor_authentication.vue");
const EducationOverview = () =>
    import("../components/examination/education_overview.vue");
const ChallengeOverview = () =>
    import("../components/challenge/challenge_overview.vue");
const EditChallenge = () =>
    import("../components/challenge/edit_challenge.vue");
const ChallengeAdminSubmission = () =>
    import("../components/challenge/ChallengeAdminSubmission.vue");
const DeclarationOverview = () =>
    import("../components/declaration/declaration_overview.vue");

const NavigationOverview = () =>
    import("../components/navigation/navigation_overview.vue");
const EditNavigation = () =>
    import("../components/navigation/edit_navigation.vue");
const EditMeeting = () => import("../components/meeting/edit_meeting.vue");
const EditALV = () => import("../components/alv/edit_alv.vue");
const AlvOverview = () => import("../components/alv/alv_overview.vue");
const AlvDetailsPage = () => import("../components/alv/alv_details_page.vue");

const MailingListsSubscriptionSelectorProfile = () =>
    import("../components/mailinglists/subscription_selector_profile.vue");

const GroupUserOverview = () =>
    import("../components/group/group_user_overview.vue");
const GroupOverview = () => import("../components/group/group_overview.vue");
const GroupRoles = () => import("../components/group/group_roles.vue");
const EditGroup = () => import("../components/group/edit_group.vue");

const ActiveMembers = () => import("../components/admin/ActiveMembers.vue");

const EditActivity = () => import("../components/activity/edit_activity.vue");
const EditNewsletter = () =>
    import("../components/newsletter/edit_newsletter.vue");
const EditNews = () => import("../components/news/edit_news.vue");
const NewsletterOverview = () =>
    import("../components/newsletter/NewsletterOverview.vue");

const UserEditAdminDataForm = () =>
    import("../components/user/edit_admin_data.vue");
const UserOAuthApplications = () =>
    import("../components/user/oauth_applications.vue");
const UserGroupTimeline = () =>
    import("../components/user/UserGroupTimeline.vue");

const TutoringOverview = () =>
    import("../components/tutoring/tutoring_overview.vue");
const TutoringRequest = () =>
    import("../components/tutoring/tutoring_request.vue");
const TutorOverview = () =>
    import("../components/tutoring/tutors_overview.vue");
const TutorCourseRegister = () =>
    import("../components/tutoring/tutor_course_register.vue");
const TutorsProfile = () => import("../components/tutoring/tutors_profile.vue");

const CourseOverview = () =>
    import("../components/examination/course_overview.vue");
const EditCourse = () => import("../components/examination/edit_course.vue");
const CreateCourse = () =>
    import("../components/examination/create_course.vue");
const EditExamination = () =>
    import("../components/examination/edit_examination.vue");
const ExaminationOverview = () =>
    import("../components/examination/examination_overview.vue");
const AdminOverview = () => import("../components/company/admin_overview.vue");
const EditCompany = () => import("../components/company/edit_company.vue");
const EditCompanyJobForm = () => import("../components/company/edit_job.vue");
const EditCompanyBannerForm = () =>
    import("../components/company/edit_banner.vue");
const EditCompanyProfileForm = () =>
    import("../components/company/edit_profile.vue");
const CompanyJobOverview = () =>
    import("../components/company/company_job_overview.vue");
const CompanyJob = () => import("../components/company/company_job.vue");
const CompanyOverview = () =>
    import("../components/company/company_overview.vue");
const CompanyProfile = () =>
    import("../components/company/company_profile.vue");

const AlbumList = () => import("../components/photos/AlbumList.vue");
const PhotoList = () => import("../components/photos/PhotoList.vue");
const PhotoModule = () => import("../components/photos/PhotoModule.vue");

const PageOverview = () => import("../components/page/page_overview.vue");
const EditPage = () => import("../components/page/edit_page.vue");

const CommitteeOverview = () =>
    import("../components/committee/committee_overview.vue");

const CommitteeSingle = () =>
    import("../components/committee/committee_single.vue");

const EditCommittee = () =>
    import("../components/committee/edit_committee.vue");

const MailingListsOverview = () =>
    import("../components/mailinglists/overview.vue");
const EditMailingListForm = () =>
    import("../components/mailinglists/edit_mailinglist.vue");

const AdminAsync = () => import("../components/admin/Async.vue");

const ContestEditForm = () =>
    import("../components/domjudge/ContestEditForm.vue");
const ContestTeamsOverview = () =>
    import("../components/domjudge/ContestUsersOverview.vue");

const BugReport = () => import("../components/bug/BugReport.vue");
const Lectures = () => import("../components/education/Lectures.vue");

const PageNotFound = () => import("../components/errors/404.vue");

export const routes = [
    { path: "/admin/async/", name: "admin-async", component: AdminAsync },
    { path: "/bug/report/", name: "bug-report", component: BugReport },
    { path: "/courses/", name: "courses", component: CourseOverview },
    {
        path: "/courses/create/",
        name: "course-create",
        component: CreateCourse,
    },
    {
        path: "/courses/:courseId(\\d+)/edit/",
        name: "course-edit",
        component: EditCourse,
    },
    { path: "/education/", name: "educations", component: EducationOverview },
    {
        path: "/tutoring/",
        name: "tutoring-overview",
        component: TutoringOverview,
    },
    {
        path: "/tutoring/request/",
        name: "tutoring-request",
        component: TutoringRequest,
    },
    { path: "/tutors/", name: "tutor-overview", component: TutorOverview },
    {
        path: "/tutors/:userId(\\d+|self)/",
        name: "tutor-profile",
        component: TutorsProfile,
    },
    {
        path: "/tutors/:userId(\\d+|self)/course/",
        name: "tutor-course-register",
        component: TutorCourseRegister,
    },
    {
        path: "/tutoring/:tutoringId(\\d+)/",
        name: "tutoring-details",
        component: TutoringRequest,
    },
    {
        path: "/pages/",
        name: "pages",
        component: PageOverview,
        meta: { title: "Pages" },
    },
    {
        path: "/pages/create/",
        name: "create-page",
        component: EditPage,
        meta: { title: "Create page" },
    },
    {
        path: "/pages/:pageId(\\d+)/edit/",
        name: "edit-page",
        component: EditPage,
        meta: { title: "Edit page" },
    },
    {
        path:
            "/history/revision/:revisionId(\\d+)/commissie/:committeePageName([\\w-]+)",
        name: "committee-single-revision",
        component: CommitteeSingle,
    },
    {
        path: "/commissie/:committeePageName([\\w-]+)",
        name: "committee-single",
        component: CommitteeSingle,
    },
    {
        path: "/commissie/",
        name: "committee-overview",
        component: CommitteeOverview,
    },
    {
        path: "/committee/create/",
        name: "committee-create",
        component: EditCommittee,
        meta: { title: "Create committee" },
    },
    {
        path: "/committee/:committeeId(\\d+)/edit/",
        name: "committee-edit",
        component: EditCommittee,
        meta: { title: "Edit committee" },
    },
    {
        path: "/examination/",
        name: "examination-overview",
        component: ExaminationOverview,
    },
    {
        path: "/examination/create/",
        name: "examination-create",
        component: EditExamination,
    },
    {
        path: "/examination/:examinationId(\\d+)/edit/",
        name: "examination-edit",
        component: EditExamination,
    },
    { path: "/users/", name: "users", component: UserOverview },
    {
        path: "/members-of-merit/",
        name: "members-of-merit",
        alias: ["/ereleden/"],
        component: MembersOfMerit,
    },
    { path: "/search/", name: "search", component: SearchIndex },
    {
        path: "/admin/active/users/",
        name: "active-members",
        meta: { title: "Active Members" },
        component: ActiveMembers,
    },
    {
        path: "/groups/",
        name: "group-overview",
        meta: { title: "Groups" },
        component: GroupOverview,
    },
    {
        path: "/groups/create/",
        name: "group-create",
        meta: { title: "Create group" },
        component: EditGroup,
    },
    {
        path: "/groups/:groupId(\\d+)/edit/",
        name: "group-edit",
        meta: { title: "Edit group" },
        component: EditGroup,
    },
    {
        path: "/groups/:groupId(\\d+)/users/",
        name: "group-users",
        component: GroupUserOverview,
    },
    {
        path: "/groups/:groupId(\\d+)/roles/",
        name: "group-roles",
        component: GroupRoles,
    },
    {
        path: "/photos/",
        component: PhotoModule,
        children: [
            {
                path: "/photos/",
                name: "Albums",
                component: AlbumList,
            },
            {
                path: "/photos/:id(\\d+)/",
                name: "Album",
                component: PhotoList,
                meta: { scrollIgnore: true },
            },
            {
                path: "/photos/:id(\\d+)/:photoid(\\d+)/",
                name: "Photo",
                component: PhotoList,
                meta: { scrollIgnore: true },
            },
        ],
    },
    {
        path: "/jobs/",
        name: "company-jobs",
        component: CompanyJobOverview,
    },
    {
        path: "/jobs/:jobId(\\d+)/",
        name: "view-company-job",
        component: CompanyJob,
        props: true,
    },
    {
        path: "/companies/",
        name: "company",
        component: CompanyOverview,
    },
    {
        path: "/companies/:companySlug/",
        name: "company-profile",
        component: CompanyProfile,
        props: true,
    },
    {
        path: "/admin/company/",
        name: "company-admin",
        component: AdminOverview,
    },
    {
        path: "/admin/company/:companyId(\\d+)?",
        name: "edit-company",
        component: EditCompany,
    },
    {
        path: "/admin/company/:companyId(\\d+)/jobs/:jobId(\\d+)?",
        name: "edit-company-job",
        component: EditCompanyJobForm,
    },
    {
        path: "/admin/company/:companyId(\\d+)/banner/",
        name: "edit-company-banner",
        component: EditCompanyBannerForm,
    },
    {
        path: "/admin/company/:companyId(\\d+)/profile/",
        name: "edit-company-profile",
        component: EditCompanyProfileForm,
    },
    {
        path: "/users/self/tfa/",
        name: "two-factor-authentication",
        component: TwoFactorAuthentication,
    },
    {
        path: "/users/:userId(\\d+|self)/mailinglist-subscriptions/",
        name: "mailinglists-subscriptions",
        component: MailingListsSubscriptionSelectorProfile,
    },
    {
        path: "/sign-up/process-saml-response/",
        name: "user-process-saml-response",
        component: UserRegister,
    },
    {
        path: "/sign-up/manual/",
        name: "user-manual-register",
        component: UserManualRegister,
    },
    {
        path: "/users/:userId(\\d+|self)/edit/",
        name: "user-profile-edit",
        component: UserProfileEdit,
    },
    {
        path: "/users/:userId(\\d+|self)/admin/",
        name: "user-admin",
        component: UserEditAdminDataForm,
    },
    {
        path: "/users/:userId(\\d+|self)/applications/",
        name: "user-applications",
        component: UserOAuthApplications,
    },
    {
        path: "/users/:userId(\\d+|self)/groups/",
        name: "user-group-timeline",
        component: UserGroupTimeline,
    },
    {
        path: "/mailinglists/",
        name: "mailinglists-overview",
        meta: { title: "Mailing lists overview" },
        component: MailingListsOverview,
    },
    {
        path: "/mailinglists/:mailingListId(\\d+)/edit/",
        name: "mailinglist-edit",
        meta: { title: "Edit mailing list" },
        component: EditMailingListForm,
    },
    {
        path: "/mailinglists/create/",
        name: "mailinglist-create",
        meta: { title: "Create mailing list" },
        component: EditMailingListForm,
    },
    {
        path: "/navigation/",
        name: "navigation-overview",
        meta: { title: "Navigation" },
        component: NavigationOverview,
    },
    {
        path: "/navigation/create/",
        name: "navigation-create",
        meta: { title: "Create navigation entry" },
        component: EditNavigation,
    },
    {
        path: "/navigation/:entryId(\\d+)/edit/",
        name: "navigation-edit",
        meta: { title: "Edit navigation entry" },
        component: EditNavigation,
    },
    {
        path: "/activities/create/",
        name: "activities-create",
        meta: { title: "Create activity" },
        component: EditActivity,
    },
    {
        path: "/activities/:activityId(\\d+)/edit/",
        name: "activities-edit",
        meta: { title: "Edit activity" },
        component: EditActivity,
    },
    {
        path: "/challenge/",
        name: "challenge-overview",
        meta: { title: "Challenges" },
        component: ChallengeOverview,
    },
    {
        path: "/challenge/:challengeId(\\d+)/edit/",
        name: "challenge-edit",
        meta: { title: "Edit challenge" },
        component: EditChallenge,
    },
    {
        path: "/challenge/:challengeId(\\d+)/add-manual-submission/",
        name: "challenge-admin-submission",
        meta: { title: "Add manual submission" },
        component: ChallengeAdminSubmission,
    },
    {
        path: "/challenge/create/",
        name: "challenge-create",
        meta: { title: "Create challenge" },
        component: EditChallenge,
    },
    {
        path: "/newsletter/",
        name: "newsletter-overview",
        component: NewsletterOverview,
    },
    {
        path: "/newsletter/:newsletterId(\\d+)/edit/",
        name: "newsletter-edit",
        component: EditNewsletter,
    },
    {
        path: "/newsletter/create/",
        name: "newsletter-create",
        component: EditNewsletter,
    },
    {
        path: "/news/:newsId(\\d+)/edit/",
        name: "news-edit",
        component: EditNews,
    },
    {
        path: "/news/create/",
        name: "news-create",
        component: EditNews,
    },
    {
        path: "/declaration/",
        name: "declaration",
        component: DeclarationOverview,
    },
    {
        path: "/meeting/:meetingId(\\d+)/edit/",
        name: "meeting-edit",
        component: EditMeeting,
    },
    {
        path: "/meeting/create/",
        name: "meeting-create",
        component: EditMeeting,
    },
    {
        path: "/pimpy/",
        component: PimpyBase,
        // All routes use the prop function to decouple the component from
        // the $route variable in vue, making testing easier.
        props: (route: RouteLocationNormalized): unknown => {
            const meta = route.meta!;

            return {
                self: !!meta.self,
                type: meta.type,
                groupId: parseInt(route.query.group as string) || null,
            };
        },
        children: [
            {
                path: "/pimpy/",
                name: "pimpy-base",
                component: PimpyTasks,
                props: {
                    self: true,
                    groupId: null,
                },
                meta: { type: "tasks", self: true },
            },
            {
                path: "tasks/create/",
                name: "pimpy-task-create",
                component: PimpyCreateTask,
                props: (route: RouteLocationNormalized): unknown => ({
                    self: false,
                    groupId: parseInt(route.query.group as string) || null,
                }),
                meta: { type: "tasks", self: false },
            },
            {
                path: "tasks/",
                name: "pimpy-tasks",
                component: PimpyTasks,
                props: (route: RouteLocationNormalized): unknown => ({
                    groupId: parseInt(route.query.group as string) || null,
                }),
                meta: { type: "tasks", self: false },
            },
            {
                path: "tasks/self/",
                name: "pimpy-tasks-self",
                component: PimpyTasks,
                props: (route: RouteLocationNormalized): unknown => ({
                    self: true,
                    groupId: parseInt(route.query.group as string) || null,
                }),
                meta: { type: "tasks", self: true },
            },
            {
                path: "minutes/create/",
                name: "pimpy-minute-create",
                component: PimpyCreateMinute,
                props: (route: RouteLocationNormalized): unknown => ({
                    groupId: parseInt(route.query.group as string) || null,
                }),
                meta: { type: "minutes" },
            },
            {
                path: "minutes/single/:minuteId(\\d+)/:line(\\d+)?/",
                name: "pimpy-minute-single",
                component: PimpySingleMinute,
                meta: { type: "minutes" },
            },
            {
                path: "minutes/",
                name: "pimpy-minutes",
                component: PimpyMinutes,
                props: (route: RouteLocationNormalized): unknown => ({
                    groupId: parseInt(route.query.group as string) || null,
                }),
                meta: { type: "minutes" },
            },
        ],
    },
    {
        path: "/alv/:alvId(\\d+)/edit/:tab?",
        name: "alv-edit",
        component: EditALV,
    },
    {
        path: "/alv/create/",
        name: "alv-create",
        component: EditALV,
    },
    {
        path: "/alv/:alvId(\\d+)/",
        name: "alv-details",
        component: AlvDetailsPage,
    },
    {
        path: "/alv/",
        name: "alv-overview",
        component: AlvOverview,
    },
    {
        path: "/redirect/",
        name: "redirect",
        component: RedirectOverview,
    },
    {
        path: "/domjudge/contest/:contestId(\\d+)/edit/",
        name: "domjudge-contest-edit",
        component: ContestEditForm,
    },
    {
        path: "/domjudge/contest/:contestId(\\d+)/users/",
        name: "domjudge-contest-users",
        component: ContestTeamsOverview,
    },
    {
        path: "/membership/complete/",
        name: "membership-communication",
        component: MailingListsSubscriptionSelectorProfile,
    },
    { path: "/lectures/", name: "lectures", component: Lectures },
    {
        path: "/:pathMatch(.*)",
        component: PageNotFound,
    },
];

export const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.meta !== undefined && to.meta.scrollIgnore) {
            return;
        }

        // Do not scroll when the query parameters change.
        if (from.name === to.name && from.path === to.path) {
            return;
        }
        if (savedPosition) {
            return savedPosition;
        }
        return { left: 0, top: 0 };
    },
});

router.beforeEach(async (to, from, next) => {
    await authStore.reloadRoles();
    next();
});

router.afterEach((to, from) => {
    if (to.meta === undefined) return;

    if (to.meta.title) document.title = to.meta.title + " - via";

    to.meta.from = from;
});
