interface FlaskJsGlue {
    url_for: (route: string, params?: Record<string, unknown>) => string;
}
declare global {
    interface Viaduct {
        locale: string;
        recaptcha_public_key: string;
        csrf_token: string;
    }
    interface Window {
        Flask: FlaskJsGlue;
        viaduct: Viaduct;
    }
}
declare let Flask: FlaskJsGlue;

export default Flask;
